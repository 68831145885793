import React, { useEffect, useState } from 'react';
import { LineContext } from 'components/context';
import AppointmentsCreatePage from 'pages/appointments/create';
import { getVendor } from 'src/services/utils';
import { setupLiff } from 'src/lib/liff';
import { fetchAndSaveAuthToken } from 'services/user.service';
import { setAuth } from 'src/services/utils';

export default function Home(props) {
  const [message, setMessage] = useState({});
  const [token, setToken] = useState();
  const [vendor, setVendor] = useState();

  useEffect(() => {
    setupLiff()
      .then(async (res) => {
        setMessage(res);
        const { userId, liffId } = res;
        const t = await fetchAndSaveAuthToken({ userId, liffId });
        setAuth({ token: t, liffId, userId });
        setVendor(getVendor(t));
        setToken(t);
      })
      .catch(async (err) => {
        // @NOTE: Debug use
        // setMessage(err);
        // const userId = 'U591e139bbc06a309d6d025cf265346f1';
        // const liffId = '2001681382-PxdGn11V';
        // const t = await fetchAndSaveAuthToken({ userId, liffId });
        // setAuth({ token: t, liffId, userId });
        // setVendor(getVendor(t));
        // setToken(t);
        console.error(err);
      });
  }, []);
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <AppointmentsCreatePage
          {...props}
          {...message}
          vendor={vendor}
        ></AppointmentsCreatePage>
      </LineContext.Provider>
    );
  }
  return null;
}
